<template>
  <div>
    <div class="t-tway mb-6" style="font-size: 18px !important">Historial de pagos</div>
    <v-simple-table v-if="subscription.payments && subscription.payments.length != 0 && desktop">
      <template v-slot:default>
        <thead class="table-header roboto">
          <tr>
            <th class="table-header-text white--text text-center">Número de Orden</th>
            <th class="table-header-text white--text text-center">Fecha de Pago</th>
            <th class="table-header-text white--text text-center">Descripción de pago</th>
            <!-- <th class="table-header-text white--text text-center">
              Comprobante
            </th> -->
          </tr>
        </thead>
        <tbody>
          <tr v-for="payment in payments" :key="payment.buyOrder">
            <td class="text-center buy-order">
              {{ payment.buyOrder }}
            </td>
            <td class="table-text text-center">
              {{ payment.paymentDate | formatDate }}
            </td>
            <td class="table-text text-center">
              Suscripción plan
              {{ payment.name }}
            </td>
            <!-- <td class="table-text text-center">
              <a>
                <v-img
                  class="icon"
                  color="tway-violet"
                  @click="getPdf(payment.comprobante)"
                  :src="require('@/assets/img/icons/carbon_download.svg')"
              /></a>
            </td> -->
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <v-row justify="center" v-if="subscription.payments && subscription.payments.length != 0 && mobile">
      <v-app-bar class="table-header-text white--text text-center" color="tway-violet" dense>
        Número de Orden
      </v-app-bar>
      <v-expansion-panels accordion>
        <v-expansion-panel v-for="payment in payments" :key="payment.buyOrder">
          <v-expansion-panel-header class="tway-violer--text">
            {{ payment.buyOrder }}
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row justify="space-between" class="mb-3 ma-0 pa-0">
              <div class="expansion-text">Fecha de Pago</div>
              <div class="expansion-text table-color">
                {{ payment.paymentDate | formatDate }}
              </div>
            </v-row>
            <v-row justify="space-between" class="mb-3 ma-0 pa-0">
              <div class="expansion-text">Descripción de Pago</div>
              <div class="expansion-text table-color">Suscripción <br />Plan {{ payment.name }}</div>
            </v-row>
            <v-row width="100%" justify="center" align="center" class="ma-0 pa-0 tway-violet--text">
              <v-btn
                large
                rounded
                color="tway-apple-green"
                class="white--text roboto"
                @click="getPdf(payment.buyOrder)"
              >
                DESCARGAR
              </v-btn>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-row>
    <v-simple-table v-if="(subscription.payments && subscription.payments.length == 0) || !subscription.payments">
      <template v-slot:default>
        <thead class="table-header2" v-if="desktop">
          <tr>
            <th class="table-header-text tway-violer--text">Número de Orden</th>
            <th class="table-header-text tway-violer--text">Fecha de Pago</th>
            <th class="table-header-text tway-violer--text">Descripción de pago</th>
            <th class="table-header-text tway-violer--text">Comprobante</th>
          </tr>
        </thead>
        <thead class="table-header2" v-if="mobile">
          <tr>
            <th class="table-header-text tway-violer--text text-center">Número de Orden</th>
          </tr>
        </thead>
      </template>
    </v-simple-table>
    <v-row
      justify="center"
      class="mt-16"
      v-if="(subscription.payments && subscription.payments.length == 0) || !subscription.payments"
    >
      <v-img :src="require('@/assets/img/mypayments.svg')" max-width="170" min-width="100" class="rounded" contain />
    </v-row>
    <v-row
      justify="center"
      class="roboto"
      style="color: #666666"
      v-if="(subscription.payments && subscription.payments.length == 0) || !subscription.payments"
    >
      Aún no tienes pagos realizados
    </v-row>
  </div>
</template>

<style lang="scss" scoped>
.table-header {
  background-color: #7319d5;
}
.table-header2 {
  background-color: #f1f1f1;
}
.table-color {
  color: #666666;
}
.table-header-text {
  font-family: Roboto Condensed !important;
  font-style: normal;
  font-weight: normal;
  font-size: 16px !important;
  line-height: 18px;
  letter-spacing: 0.05em;
}
.table-text {
  font-family: Roboto Condensed !important;
  font-style: normal;
  font-weight: normal;
  font-size: 16px !important;
  line-height: 18px;
  letter-spacing: 0.05em;
  color: #666666;
  border-left: 1px solid #cccccc;
}
.expansion-text {
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
}
.black-text {
  color: #333333;
}
.buy-order {
  color: #7319d5;
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: normal;
  font-size: 16px !important;
  line-height: 24px;

  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 30px 0px;
}
.item-table {
  position: static;
  left: 0%;
  right: 0%;
  top: 0%;
  bottom: 81.82%;

  /* Tway / P - Default */

  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: normal;
  font-size: 16px !important;
  line-height: 24px;
  /* identical to box height, or 150% */

  /* Gris Oscuro / Gris Oscuro 2 */

  color: #666666;

  /* Inside Auto Layout */

  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 30px 0px;
}

.icon {
  width: 24px;
  height: 24px;
  left: 47%;
  right: 0%;
  top: 0%;
}

.tway-violer--text {
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  /* Tway / Violeta */

  color: #7319d5;
}
</style>
<script>
import subscriptionService from "@/services/subscriptionService";
import planService from "@/services/planService";

export default {
  name: "MyPayment",

  data: () => ({
    subscription: {},
    plan: {
      name: "",
    },
  }),

  created() {
    this.getSubscription();
  },

  methods: {
    getSubscription: function () {
      subscriptionService
        .getCurrentUserSubscription()
        .then((subscription) => {
          let payments = subscription.payments.map((e) => {
            e.name = "";
            return e;
          });
          subscription.payments = payments;
          subscription.payments.sort((a, b) => (b.paymentDate > a.paymentDate ? 1 : -1));
          this.subscription = subscription;
          this.subscription.payments.forEach((element) => {
            planService
              .findPlan(element.planId)
              .then((plan) => {
                element.name = plan.name;
                this.subscription.payments = this.subscription.payments.filter((i) => i != -1);
              })
              .catch((err) => this.$log.error("Unexpected error: ", err));
          });
        })
        .catch((err) => this.$log.error("Unexpected error: ", err))
        .then(() => (this.isLoading = false));
    },
    getPdf: function (pdf) {
      subscriptionService
        .getPdf(pdf)
        .then(function (response) {
          let blob = new Blob([response.data], { type: "application/pdf" });
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = pdf + ".pdf";
          link.click();
        })
        .catch((err) => this.$log.error("Unexpected error: ", err));
    },
  },
  computed: {
    payments() {
      return this.subscription.payments;
    },
    mobile() {
      return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs;
    },
    desktop() {
      return this.$vuetify.breakpoint.md || this.$vuetify.breakpoint.lg || this.$vuetify.breakpoint.xl;
    },
  },
};
</script>
